import { VLayout } from 'vuetify/lib/components/VGrid';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    VLayout,
    { style: _vm.layoutStyle },
    [
      _vm.modelingHtml
        ? _c("v-runtime-template", {
            attrs: { template: "<div>" + _vm.modelingHtml + "</div>" },
          })
        : _c(VProgressCircular, {
            attrs: { size: 50, color: "primary", indeterminate: "" },
          }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }